var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{directives:[{name:"background-3",rawName:"v-background-3"}],staticClass:"d-flex align-items-center rounded shadow-sm p-2 m-1 text-truncate"},[(_vm.data.affaire_activite_id && _vm.data.affaire_activite_id == 1)?_c('div',[_c('div',{staticClass:"avatar mr-3 border-0",style:({
          backgroundImage: 'url(/affaires/1.png)',
          width: '2rem',
          height: '2rem',
        })})]):_vm._e(),(_vm.data.affaire_activite_id && _vm.data.affaire_activite_id == 2)?_c('div',[_c('div',{staticClass:"avatar mr-3 border-0",style:({
          backgroundImage: 'url(/affaires/2.svg)',
          width: '2rem',
          height: '2rem',
        })})]):_vm._e(),(_vm.data.affaire_activite_id && _vm.data.affaire_activite_id == 3)?_c('div',[_c('div',{staticClass:"avatar mr-3 border-0",style:({
          backgroundImage: 'url(/affaires/3.svg)',
          width: '2rem',
          height: '2rem',
        })})]):_vm._e(),(_vm.data.affaire_activite_id && _vm.data.affaire_activite_id == 4)?_c('div',[_c('div',{staticClass:"avatar mr-3 border-0",style:({
          backgroundImage: 'url(/affaires/4.png)',
          width: '2rem',
          height: '2rem',
        })})]):_vm._e(),(_vm.data.affaire_activite_id && _vm.data.affaire_activite_id == 5)?_c('div',[_c('div',{staticClass:"avatar mr-3 border-0",style:({
          backgroundImage: 'url(/affaires/4.png)',
          width: '2rem',
          height: '2rem',
        })})]):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.data.affaire_identifiant))]),(_vm.data.affaire_new)?_c('span',{staticClass:"ml-2 badge badge-primary"},[_vm._v("Nouveau")]):_vm._e()]),_c('div',{staticStyle:{"line-height":"1rem"}},[(_vm.data.affaire_libelle)?_c('div',{staticClass:"text-muted text-truncate",staticStyle:{"max-width":"250px"}},[_c('small',[_vm._v(_vm._s(_vm.data.affaire_libelle))])]):_vm._e(),(!_vm.data.affaire_libelle)?_c('div',{staticClass:"text-muted text-truncate",staticStyle:{"max-width":"250px"}},[_c('small',[_vm._v("Aucun libellé")])]):_vm._e(),(_vm.data.affaire_comptes)?_c('div',{staticClass:"text-truncate text-muted",staticStyle:{"max-width":"300px"}},[_c('small',[_vm._v(_vm._s(_vm.data.affaire_comptes.description))])]):_vm._e()])]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.data.affaire_etat_id === 1)?_c('BaseIcon',{staticClass:"text-primary m-auto",attrs:{"name":"circle","width":"10","height":"10","fill":"#007bff"}}):_vm._e(),(_vm.data.affaire_etat_id === 2)?_c('BaseIcon',{staticClass:"text-warning m-auto",attrs:{"name":"circle","width":"10","height":"10","fill":"#ffc107"}}):_vm._e(),(_vm.data.affaire_etat_id === 3)?_c('BaseIcon',{staticClass:"text-success m-auto",attrs:{"name":"circle","width":"10","height":"10","fill":"#28a745"}}):_vm._e(),(_vm.data.affaire_etat_id === 4)?_c('BaseIcon',{staticClass:"text-success m-auto",attrs:{"name":"circle","width":"10","height":"10","fill":"#dc3545","color":"#dc3545"}}):_vm._e()],1),_c('button-circle',{staticClass:"ml-3",attrs:{"name":"external-link","size":"18"},on:{"click":function($event){return _vm.$emit('show', _vm.data.id)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }