<template>
  <div class>
    <div class="d-flex align-items-center rounded shadow-sm p-2 m-1 text-truncate" v-background-3>
      <div v-if="data.affaire_activite_id && data.affaire_activite_id == 1">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/1.png)',
            width: '2rem',
            height: '2rem',
          }"
        ></div>
      </div>
      <div v-if="data.affaire_activite_id && data.affaire_activite_id == 2">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/2.svg)',
            width: '2rem',
            height: '2rem',
          }"
        ></div>
      </div>
      <div v-if="data.affaire_activite_id && data.affaire_activite_id == 3">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/3.svg)',
            width: '2rem',
            height: '2rem',
          }"
        ></div>
      </div>
      <div v-if="data.affaire_activite_id && data.affaire_activite_id == 4">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/4.png)',
            width: '2rem',
            height: '2rem',
          }"
        ></div>
      </div>
      <div v-if="data.affaire_activite_id && data.affaire_activite_id == 5">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/4.png)',
            width: '2rem',
            height: '2rem',
          }"
        ></div>
      </div>
      <div class="flex-grow-1">
        <div class="d-flex align-items-center">
          <span>{{ data.affaire_identifiant }}</span>
          <span class="ml-2 badge badge-primary" v-if="data.affaire_new">Nouveau</span>
        </div>
        <div style="line-height: 1rem">
          <div class="text-muted text-truncate" style="max-width: 250px" v-if="data.affaire_libelle">
            <small>{{ data.affaire_libelle }}</small>
          </div>
          <div class="text-muted text-truncate" style="max-width: 250px" v-if="!data.affaire_libelle">
            <small>Aucun libellé</small>
          </div>
          <div class="text-truncate text-muted" style="max-width: 300px" v-if="data.affaire_comptes">
            <small>{{ data.affaire_comptes.description }}</small>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="text-primary m-auto"
          fill="#007bff"
          v-if="data.affaire_etat_id === 1"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="text-warning m-auto"
          fill="#ffc107"
          v-if="data.affaire_etat_id === 2"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="text-success m-auto"
          fill="#28a745"
          v-if="data.affaire_etat_id === 3"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="text-success m-auto"
          fill="#dc3545"
          color="#dc3545"
          v-if="data.affaire_etat_id === 4"
        ></BaseIcon>
      </div>
      <button-circle class="ml-3" name="external-link" size="18" @click="$emit('show', data.id)"></button-circle>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { BaseIcon, ButtonCircle },
  props: {
    data: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {},
};
</script>
<style lang="css"></style>
